<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb title="Profilim" :array="[{islink:true,url:'/',text:'Ana Sayfa'},{islink:false,url:'',text:'Profilim'}]"/>
    </section>
    <!--Breadcrumb-->

    <!--User Dashboard-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <Sidebar />
          <router-view id="content-app"></router-view>
        </div>
      </div>
    </section>
    <!--/User Dashboard-->
  </div>
</template>
<style scoped>
.page-header .breadcrumb-item + .breadcrumb-item::before {
  color: #504e6f !important;
}
@media (max-width: 480px) {
  .page-title {
    display: none;
  }
  .breadcrumb {
    margin: 0 auto;
    width: fit-content;
  }
}
</style>
<script>
import Breadcrumb from '../../components/Breadcrumb.vue';
import Sidebar from "../Profile/Component/Sidebar.vue";
export default {
  components: {
    Sidebar,
    Breadcrumb,
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  mounted() {
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/63ac17f547425128790a7159/1glc3r6g9';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
    /**TOKEN CONTROL */
    this.$store.dispatch("TokenControl");
    (function () {
      "use strict";

      var slideMenu = $(".side-menu");

      // Toggle Sidebar
      $('[data-bs-toggle="sidebar"]').click(function (event) {
        event.preventDefault();
        $(".app").toggleClass("sidenav-toggled");
      });

      $(window).on("load resize", function () {
        if ($(window).width() < 739) {
          $(".app-sidebar").hover(function (event) {
            event.preventDefault();
            $(".app").addClass("sidenav-toggled");
          });
        }
        if ($(window).width() > 739) {
          $(".app-sidebar").hover(function (event) {
            event.preventDefault();
            $(".app").removeClass("sidenav-toggled");
          });
        }
      });

      // Activate sidebar slide toggle
      $("[data-bs-toggle='slide']").click(function (event) {
        event.preventDefault();
        if (!$(this).parent().hasClass("is-expanded")) {
          slideMenu
            .find("[data-bs-toggle='slide']")
            .parent()
            .removeClass("is-expanded");
        }
        $(this).parent().toggleClass("is-expanded");
      });
    })();
  },
};
</script>
