<template>
  <div class="col-xl-3 col-lg-12 col-md-12">
    <div class="card" v-if="$store.state.user_type == ''">
      <div class="card-header">
        <h3 class="skeleton w-100 p-3 card-title"></h3>
      </div>
      <div class="card-body text-center item-user">
        <div class="profile-pic">
          <div class="profile-pic-img">
            <div
              style="height: 80px"
              class="skeleton w-100 brround"
              alt="user"
            ></div>
          </div>
          <h4 class="mt-3 mb-0 font-weight-semibold skeleton w-100 p-3"></h4>
          <div class="skeleton w-70 p-1 mt-2"></div>
        </div>
      </div>
      <aside class="app-sidebar doc-sidebar my-dash">
        <div class="app-sidebar__user clearfix">
          <ul class="side-menu">
            <li class="slide" v-for="item in 8" :key="item">
              <a class="skeleton w-100 mt-1 p-5 side-menu__item"
                ><span class="side-menu__label"></span
              ></a>
            </li>
          </ul>
        </div>
      </aside>
    </div>
    <div class="card" v-if="$store.state.user_type != ''">
      <div class="card-header">
        <h3 class="card-title">Profilim</h3>
      </div>
      <div class="card-body text-center item-user">
        <div class="profile-pic">
          <div class="profile-pic-img">
            <span
              class="bg-success dots"
              @click="profileImageUpload"
              style="width: auto; cursor: pointer"
              ><i class="side-menu__icon si si-pencil"></i
            ></span>
            <img
              :src="
                'https://storage.terapivitrini.com/' +
                $store.state.profile_image
              "
              class="brround"
              alt="user"
            />
          </div>
          <h4 class="mt-3 mb-0 font-weight-semibold">
            {{ $store.state.fullname }}
          </h4>
          <small
            ><b>Üyelik Türü:</b>
            <template v-if="$store.state.user_type == 'EXPERT'"
              >Terapist</template
            >
            <template v-if="$store.state.user_type == 'MEMBER'">Üye</template>
            <template v-if="$store.state.user_type == 'USER'"
              >Sistem Kullanıcısı</template
            >
            <template v-if="$store.state.user_type == 'CENTER'"
              >Terapi Merkezi</template
            >
          </small>
          <template v-if="$store.state.user_type == 'EXPERT'"
            ><router-link
              :to="'/terapist/' + $store.state.shortlink"
              class="btn btn-primary mt-3 w-100"
            >
              Profil Görüntüle
            </router-link>
          </template>
        </div>
      </div>
      <aside class="app-sidebar doc-sidebar my-dash">
        <div class="app-sidebar__user clearfix">
          <ul class="side-menu">

            <li class="slide general-info is-expanded">
              <a
                @click="openList('general-info')"
                class="side-menu__item"
                data-bs-toggle="slide"
                ><i class="side-menu__icon si si-user"></i
                ><span class="side-menu__label">Profilim</span
                ><i class="angle fa fa-angle-right"></i
              ></a>
              <ul class="slide-menu">
                <li @click="goto">
                  <router-link to="/profile/general" class="slide-item"
                    ><i class="side-menu__icon si si-user"></i
                    ><span class="side-menu__label"
                      >Genel Bilgiler</span
                    ></router-link
                  >
                </li>
                <li
                  @click="backToTop"
                  v-if="$store.state.user_type == 'EXPERT' || $store.state.user_type == 'CENTER'"
                >
                  <router-link to="/profile/biographie" class="slide-item"
                    ><i class="side-menu__icon si si-list"></i
                    ><span class="side-menu__label"
                      >Biyografi</span
                    ></router-link
                  >
                </li>
                <li class="slide" @click="backToTop">
                  <router-link to="/profile/phones" class="slide-item"
                    ><i class="side-menu__icon si si-phone"></i
                    ><span class="side-menu__label">Telefon</span></router-link
                  >
                </li>

                <li class="slide" @click="backToTop">
                  <router-link to="/profile/address" class="slide-item"
                    ><i class="side-menu__icon si si-map"></i
                    ><span class="side-menu__label">Adres</span></router-link
                  >
                </li>
                

                <li
              @click="backToTop"
              v-if="$store.state.user_type == 'EXPERT'"
            >
              <router-link
                to="/profile/experiences"
                class="slide-item"
                href="#"
                ><i class="side-menu__icon si si-diamond"></i
                ><span class="side-menu__label">Hizmet</span></router-link
              >
            </li>
            <li
                  @click="goto"
                  v-if="
                    $store.state.user_type == 'EXPERT'
                  "
                >
                  <router-link
                    to="/profile/education/subscribe"
                    class="slide-item"
                    ><i class="side-menu__icon si si-plus"></i
                    ><span class="side-menu__label"
                      >Ödeme Alma Bilgileri</span
                    ></router-link
                  >
                </li>
            <li
                  class="slide"
                  @click="backToTop"
                  v-if="$store.state.user_type == 'EXPERT'  || $store.state.user_type=='CENTER'"
                >
                  <router-link to="/profile/media" class="slide-item"
                    ><i class="side-menu__icon si si-picture"></i
                    ><span class="side-menu__label">Medya</span></router-link
                  >
                </li>
                <li class="slide" @click="backToTop">
                  <router-link to="/profile/password-reset" class="slide-item"
                    ><i class="side-menu__icon si si-key"></i
                    ><span class="side-menu__label"
                      >Parola Yenile</span
                    ></router-link
                  >
                </li>
                <li class="slide" @click="backToTop" v-if="$store.state.user_type == 'EXPERT'">
                  <router-link to="/profile/owner-meeting-identity-info" class="slide-item"
                    ><i class="side-menu__icon si si-key"></i
                    ><span class="side-menu__label"
                      >Online Görüşme Kimlik Bilgisi</span
                    ></router-link
                  >
                </li>
                
                
              </ul>
            </li>
            <li
              class="slide"
              @click="backToTop"
              v-if="$store.state.user_type == 'EXPERT'"
            >
              <router-link
                to="/profile/calendar"
                class="side-menu__item"
                href="#"
                ><i class="side-menu__icon si si-calendar"></i
                ><span class="side-menu__label"
                  >Randevu Takvimi</span
                ></router-link
              >
            </li>
            <li
              class="slide"
              @click="backToTop"
              v-if="$store.state.user_type == 'EXPERT'"
            >
              <router-link
                to="/profile/appointments/create"
                class="side-menu__item"
                href="#"
                ><i class="side-menu__icon si si-plus"></i
                ><span class="side-menu__label"
                  >Randevu Oluştur</span
                ></router-link
              >
            </li>

            <li class="slide" @click="backToTop">
              <router-link
                to="/profile/appointments"
                class="side-menu__item"
                href="#"
                ><i class="side-menu__icon si si-clock"></i
                ><span class="side-menu__label">Randevularım</span></router-link
              >
            </li>

            <li
              class="slide blogs"
              v-if="
                $store.state.user_type == 'EXPERT' && $store.state.trainer == 1
              "
            >
              <a
                @click="openList('blogs')"
                class="side-menu__item"
                data-bs-toggle="slide"
                ><i class="side-menu__icon si si-layers"></i
                ><span class="side-menu__label">Makale</span
                ><i class="angle fa fa-angle-right"></i
              ></a>
              <ul class="slide-menu">
                <li
                  @click="goto"
                  v-if="
                    $store.state.user_type == 'EXPERT' &&
                    $store.state.trainer == 1
                  "
                >
                  <router-link to="/profile/blogs/create" class="slide-item"
                    ><i class="side-menu__icon si si-plus"></i
                    ><span class="side-menu__label"
                      >Yeni Makale</span
                    ></router-link
                  >
                </li>
                <li
                  @click="goto"
                  v-if="
                    $store.state.user_type == 'EXPERT' &&
                    $store.state.trainer == 1
                  "
                >
                  <router-link to="/profile/blogs" class="slide-item"
                    ><i class="side-menu__icon si si-paper-clip"></i
                    ><span class="side-menu__label"
                      >Makale Listesi</span
                    ></router-link
                  >
                </li>
              </ul>
            </li>
            <li class="slide education">
              <a
                @click="openList('education')"
                class="side-menu__item"
                data-bs-toggle="slide"
                ><i class="side-menu__icon si si-book-open"></i
                ><span class="side-menu__label">Eğitim</span
                ><i class="angle fa fa-angle-right"></i
              ></a>
              <ul class="slide-menu">
                <li
                  @click="goto"
                  v-if="
                    $store.state.user_type == 'EXPERT' &&
                    $store.state.trainer == 1
                  "
                >
                  <router-link to="/profile/education/create" class="slide-item"
                    ><i class="side-menu__icon si si-plus"></i
                    ><span class="side-menu__label"
                      >Yeni Eğitim</span
                    ></router-link
                  >
                </li>
                <li
                  @click="goto"
                  v-if="
                    $store.state.user_type == 'EXPERT' &&
                    $store.state.trainer == 1
                  "
                >
                  <router-link to="/profile/education" class="slide-item"
                    ><i class="side-menu__icon si si-paper-clip"></i
                    ><span class="side-menu__label"
                      >Eğitim Listesi</span
                    ></router-link
                  >
                </li>
                <li @click="goto">
                  <router-link to="/profile/education/me" class="slide-item"
                    ><i class="side-menu__icon si si-badge"></i
                    ><span class="side-menu__label"
                      >Alınan Eğitim Listesi</span
                    ></router-link
                  >
                </li>
                
              </ul>
            </li>
            <li
              class="slide"
              @click="backToTop"
              v-if="$store.state.user_type == 'EXPERT'"
            >
              <router-link to="/profile/billing" class="side-menu__item"
                ><i class="fa fa-credit-card"></i
                ><span class="side-menu__label" style="margin-left: 10px"
                  >Fatura & Ödeme</span
                ></router-link
              >
            </li>

            <li
              class="slide"
              @click="backToTop"
              v-if="$store.state.user_type == 'EXPERT'"
            >
              <router-link to="/profile/pay" class="side-menu__item"
                ><i class="fa fa-cc-visa"></i
                ><span class="side-menu__label" style="margin-left: 10px"
                  >Ödeme Al</span
                ></router-link
              >
            </li>

           
            

            
            <li
              class="slide"
              @click="backToTop"
              v-if="$store.state.user_type == 'EXPERT'"
            >
              <router-link
                to="/profile/client/forms"
                class="side-menu__item"
                href="#"
                ><i class="side-menu__icon si si-paper-clip"></i
                ><span class="side-menu__label">Danışan Takip Formları</span></router-link
              >
            </li>
            <li
              class="slide"
              @click="backToTop"
              v-if="$store.state.user_type == 'EXPERT' || $store.state.user_type == 'CENTER'"
            >
              <router-link to="/profile/comments" class="side-menu__item"
                ><i class="side-menu__icon si si-bubble"></i
                ><span class="side-menu__label">Yorum <span class="badge badge-primary" v-if="$store.state.commentSize>0">{{$store.state.commentSize}}</span></span></router-link
              >
            </li>

            <li
              class="slide"
              @click="backToTop"
              v-if="$store.state.user_type == 'EXPERT' || $store.state.user_type=='CENTER'"
            >
              <router-link
                to="/profile/statics"
                class="side-menu__item"
                href="#"
                ><i class="side-menu__icon si si-graph"></i
                ><span class="side-menu__label"
                  >İstatistikler</span
                ></router-link
              >
            </li>
            <li @click="backToTop">
              <a @click="logout" class="side-menu__item" style="cursor: pointer"
                ><i class="side-menu__icon si si-power"></i
                ><span class="side-menu__label">Çıkış Yap</span></a
              >
            </li>
          </ul>
        </div>
      </aside>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    var slideMenu = $(".side-menu");
    // Activate sidebar slide toggle
    $("[data-bs-toggle='slide']").click(function (event) {
      event.preventDefault();
      if (!$(this).parent().hasClass("is-expanded")) {
        slideMenu
          .find("[data-bs-toggle='slide']")
          .parent()
          .removeClass("is-expanded");
          
      }
      $(this).parent().toggleClass("is-expanded");
    });
  },
  data() {
    return {
      general: false,
    };
  },
  mounted(){
    var slideMenu = $(".general-info");
    slideMenu.addClass("is-expanded");
  },
  methods: {
    openList(item) {
      var slideMenu = $("." + item);
      if (this.general) {
        this.general = false;
        slideMenu.removeClass("is-expanded");
      } else {
        slideMenu.addClass("is-expanded");
        this.general = true;
      }
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: $("#content-app").offset().top - 60,
        },
        0
      );
      return false;
    },
    logout() {
      Swal.fire({
        title: "Çıkış yapmak istediğinize emin misiniz?",
        text: "Terapi Vitrininden çıkış yapmak üzeresin, işlemi onaylıyor musun?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Çıkış Yap",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("logout");
        }
      });
    },
    goto() {
      $("html, body").animate(
        {
          scrollTop: $("#content-app").offset().top - 10,
        },
        0
      );
    },
    profileImageUpload() {
      this.backToTop();
      this.$router.push("/profile/profile-image");
    },
  },
};
</script>